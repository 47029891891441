import { SoftPos } from 'types/softpos';
import { postingsSelector } from 'features/settlements/redux/selectors';
import { ExportSelect } from 'features/shared';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DATE_FORMATS } from 'utils';
import { formatCurrency } from 'utils';
import { terminalCollectionSelector } from 'features/user/redux/selectors';

export type PostingsExportSelectProps = {
  printElementRef: React.MutableRefObject<HTMLDivElement | null>;
};

const PostingsExportSelect: React.FC<PostingsExportSelectProps> = (props) => {
  const postingsItems = useSelector(postingsSelector);
  const { t } = useTranslation();
  const terminalCollection = useSelector(terminalCollectionSelector);

  const csvData = useMemo(() => {
    const data = [];

    const headers: {
      label: string;
      key: keyof (SoftPos.Posting & { terminalName: string });
    }[] = [
      {
        label: t('SettlementDetails.table_header.card_type'),
        key: 'scheme',
      },
      {
        label: t('SettlementDetails.table_header.date'),
        key: 'processedAt',
      },
      {
        label: t('SettlementDetails.table_header.last_four'),
        key: 'lastFour',
      },
      {
        label: t('SettlementDetails.table_header.terminal_id'),
        key: 'terminalId',
      },
      {
        label: t('SettlementDetails.table_header.net_amount'),
        key: 'settlementAmount',
      },
      {
        label: t('SettlementDetails.table_header.fee_amount'),
        key: 'settlementFeeAmount',
      },
      {
        label: t('SettlementDetails.table_header.gross_amount'),
        key: 'transactionAmount',
      },
      {
        label: t('Transactions.table_header.terminal_name'),
        key: 'terminalName',
      },
    ];

    for (const trxn of postingsItems) {
      const temp: Record<string, string | number | null> = {};

      for (const header of headers) {
        if (header.key === 'processedAt') {
          temp[header.key] = moment(trxn[header.key]).format(DATE_FORMATS.date);
        } else if (header.key === 'lastFour') {
          temp[header.key] =
            '****' + trxn['lastFour']?.substring(trxn['lastFour']?.length - 4);
        } else if (header.key === 'transactionAmount') {
          const gross = formatCurrency(trxn.transactionAmount / 100);
          temp[header.key] = gross ? gross : '';
        } else if (header.key === 'settlementAmount') {
          const sa = formatCurrency(trxn.settlementAmount / 100);
          temp[header.key] = sa ? sa : '';
        } else if (header.key === 'settlementFeeAmount') {
          const sfa = formatCurrency(trxn.settlementFeeAmount / 100);
          temp[header.key] = sfa ? sfa : '';
        } else if (header.key === 'terminalName') {
          const name = terminalCollection.find((terminal) => {
            if (terminal.terminalId.startsWith('ti_')) {
              return (
                terminal.softpayConfig?.onlineTerminalId === trxn['terminalId']
              );
            } else {
              return terminal.terminalId === trxn['terminalId'];
            }
          })?.name;
          temp[header.key] = name ? name : 'NA';
        } else {
          temp[header.key] = trxn[header.key];
        }
      }

      data.push(temp);
    }

    return { headers, data };
  }, [postingsItems]);

  return (
    <ExportSelect
      filename="payout"
      headers={csvData.headers}
      data={csvData.data}
      printElementRef={props.printElementRef}
    />
  );
};

export { PostingsExportSelect };
